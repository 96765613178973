<template>
  <div class="footer">
    <div class="content fs">
      <ul>
        <li>联系方式</li>
        <li>
          <img height="18" src="@/assets/img/Index_JobSeeker/icon5.png" alt="" />{{footerInfo.contact}}
        </li>
        <li>
          <img height="16" src="@/assets/img/Index_JobSeeker/icon30.png" alt="" />{{footerInfo.email}}
        </li>
        <li>
          <img height="16" src="@/assets/img/Index_JobSeeker/icon31.png" alt="" />{{footerInfo.mobile}}
        </li>
      </ul>
      <ul>
        <li v-html="unEscapeHTML(footerInfo.aboutUs)"></li>
        <li>
          <p><span>版权：</span>{{footerInfo.copyright}}</p>
          <p><span>备案号：</span>{{footerInfo.recordNo}}</p>
        </li>
      </ul>
      <ul>
        <div>
          <li><img :src="footerInfo.qrcode" alt="" /></li>
          <li>微信小程序</li>
        </div>
        <div>
          <li><img :src="footerInfo.prcode" alt="" /></li>
          <li>公众号</li>
        </div>
        <div>
          <li><img :src="footerInfo.drcode" alt="" /></li>
          <li>抖音小程序</li>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import { footer } from '@/api/index'
export default {
  name: "footers",
  data(){
    return {
      footerInfo: {}
    }
  },
  created(){
    footer().then(res => {
      this.footerInfo = res.data
    })
  },
  methods: {
    unEscapeHTML(html) {
        html= "" +html;
        return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'");
    },
  }
};
</script>
<style scoped>
.footer {
  height: 198px;
  background-color: #ffffff;
  padding: 24px 0;
  color: #666;
}
.footer img {
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.footer ul:nth-child(1) {
  width: 250px;
  text-align: left;
}
.footer ul:nth-child(2) {
  width: 658px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #EEEEEE;
  border-right: 1px solid #EEEEEE;
}
.footer ul:nth-child(3) {
  width: 292px;
  text-align: center;
}

.footer ul:nth-child(1) li:nth-child(1) {
  height: 20px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #999999;
}
.footer ul:nth-child(1) li:nth-child(2){
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
  margin-bottom: 21px;
}
.footer ul:nth-child(1) li:nth-child(3){
  margin-bottom: 21px;
}
.footer ul:nth-child(1) li {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
}
.footer ul:nth-child(1) li img {
  margin-right: 8px;
}
.footer ul:nth-child(2) li {
  width: 476px;
  text-align: left;
}
.footer ul:nth-child(2) li:nth-child(1) {
  height: 68px;
  line-height: 24px;
  margin-bottom: 62px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.footer ul:nth-child(2) li:nth-child(2) {
  display: flex;
  justify-content: space-between;
}
.footer ul:nth-child(2) li:nth-child(2) span {
  color: #999;
}
.footer ul:nth-child(3){
  display: flex;
  margin-left: 16px;
  width: 276px;
  justify-content: space-between;
  align-items: center;
}
.footer ul:nth-child(3) img {
  width: 73px;
  height: 73px;
  margin-bottom: 4px;
}
</style>
