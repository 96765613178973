<template>
  <div :class="navBarFixed == true ? 'navBarWrap head' : 'head'">
    <div class="content nav fcs">
      <div @click="!recruit?$router.push('/'):$router.push('/recruit')"><img height="40"  src="@/assets/img/Index_JobSeeker/logo.png" alt="" /></div>
      <div class="subNav fcs">
        <span
          :class="navId == index ? 'active' : ''"
          @click="changeNav(index)"
          v-for="(item, index) in navList"
          :key="index"
          >{{ item }}</span
        >
      </div>
      <div class="search">
        <el-input
          :placeholder="!recruit ? '搜索公司、职位' : '搜索职位'"
          prefix-icon="el-icon-search"
          v-model="searchVal"
          :clearable="true"
          @keyup.enter.native="goSearch"
        >
        </el-input>
      </div>
      <div class="tool fcs">
        <el-badge :value="isnew" class="item" v-if="isnew">
          <p @click="qimoChatClick"><img width="18"  src="../assets/img/Index_JobSeeker/icon1.png" alt="" />消息</p>
        </el-badge>
        <p v-else @click="qimoChatClick"><img width="18" src="../assets/img/Index_JobSeeker/icon1.png" alt="" />消息</p>
        <p v-if="!recruit" @click="$router.push('/myResume')">
          <img width="18" src="../assets/img/Index_JobSeeker/icon2.png" alt="" />简历
        </p>
        <el-dropdown v-if="userInfo">
          <div class="user" >
            <div class="headPortrait" :style="'background-image:url('+(userInfo.avatar?userInfo.avatar: '/head-portrait2.png')+')'"></div>
<!--            <el-tooltip class="item" effect="light" :content="userInfo.userName">-->
        <span class="username" :title="userInfo.userName">{{ userInfo.userName }}</span>
<!--            </el-tooltip>-->
          </div>
          <el-dropdown-menu slot="dropdown" class="dropList">
            <el-dropdown-item
              ><p @click="!recruit?$router.push('/personalCenter'):$router.push('/recruitcenter')">个人中心</p>
            </el-dropdown-item>
            <el-dropdown-item><p @click="logout">退出登录</p></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <p v-else class="user">
          <span @click="$router.push('/login')">登录</span> /
          <span @click="$router.push('/register')">注册</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import event from '@/utils/event';
import { getBaseInfo } from "@/api/zp";
export default {
  name: "Head",
  props: ["Offset", "hId", "search"],
  inject: ["reload"],
  data() {
    return {
      navList: ["首页", "我要找工作", "新闻资讯", "关于我们"],
      navId: this.hId,
      navBarFixed: false,
      searchVal: this.$route.query.value,
      userInfo: '',
      recruit: '',
      isnew: 0
    };
  },
  mounted() {
    const userInfo = window.sessionStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
      if(this.userInfo.loginDO.loginType === 2){
        this.navList[1] = '我要招人'
        this.recruit = true
      }
      this.getInfoFn()
    }
    window.addEventListener("scroll", this.watchScroll);
    event.$on('getInfoFn', this.getInfoFn)
    this.timeer = setInterval(() => {
      window.getUnReadMsgList(this.userInfo.userId||'', 1, (e) => {
      if(e.list && e.list.length) {
        this.isnew = e.list.length
      }
    })
    }, 5000);
  },
  destroyed() {
    clearInterval(this.timeer)
  },

  methods: {
    qimoChatClick(){
      this.$router.push('/chart')
    },
    // 跳转到搜索界面
    goSearch() {
      if (!this.recruit) {
        this.$router.push({
          path: "/searchJob",
          query: { value: this.searchVal },
        });
      } else {
        this.$router.push({
          path: "/recruit/search",
          query: { value: this.searchVal },
        });
      }
    },
    // 切换导航
    changeNav(id) {
      this.navId = id;
      if (id == 0) {
        if(!this.recruit){
          this.$router.push({ path: "/" });
        } else {
          this.$router.push({ path: "/recruit" });
        }
      }
      if (id == 1) {
        if(!this.recruit){
          this.$router.push({ path: "/searchJob" });
        } else {
          this.$router.push({ path: "/recruit/search" });
        }
      }
      if (id == 2) {
        this.$router.push({ path: "/news" });
      }
      if (id == 3) {
        this.$router.push({ path: "/about" });
      }
    },
    logout() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          sessionStorage.removeItem("userInfo");
          this.$router.push({ path: "/" });
          // this.$message({
          //   type: "success",
          //   message: "操作成功！",
          // });
          this.reload();
        })
        .catch(() => {});
    },
    // 吸顶导航
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 80) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },

    // 获取基本信息
    getInfoFn() {
      // if(this.recruit){
      //   getRecruitInfo({}).then(() => {
      //     // this.userInfo = res.data;
      //   });
      // } else {
        getBaseInfo({}).then((res) => {
          this.userInfo = res.data;
          let userInfo = window.sessionStorage.getItem('userInfo')
          if(userInfo) {
            userInfo = JSON.parse(userInfo)
            userInfo.loginDO = res.data.loginDO
            userInfo.avatar = res.data.avatar
            window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
          }
          event.$emit('loadUserInfo')
        });
      // }
    },
  },
};
</script>
<style scoped>
.head {
  height: 80px;
  background-color: #0780e6;
}
.head .nav {
  height: 80px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
}
.head .subNav {
  width: 406px;
  margin-left: 26px;
}
.head .subNav span {
  cursor: pointer;
}
.head .subNav .active {
  color: #f3f623;
  font-weight: bold;
}
.head .search {
  width: 245px;
  height: 40px;
}
.head .search /deep/ .el-input__inner {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  border: none;
}
.head .search /deep/ .el-input__inner::-webkit-input-placeholder,
.head .search /deep/ .el-input__prefix {
  color: #ffffff;
}

.head .tool {
 /*width: 256px;*/
  position: relative;
}
.head .tool p {
  flex: 1;
  text-align: left;
  cursor: pointer;
  padding-right: 10px;
  /*margin-right: 31px;*/
}
.head .tool img {
  margin-right: 8px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.user {
  max-width: 142px;
  height: 42px;
  flex: none !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 42px;
  padding-left: 10px;
  color: white;
  font-size: 16px;

}
.user img {
  top: 0 !important;
}
.head .headPortrait {
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-size: 100% auto;
  background-position: center center;
  float: left;
  margin-right: 10px;
}
.head .username{
  cursor: pointer;
}

.navBarWrap {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}
</style>
<style>
.el-dropdown-menu.dropList {
  top: 68px !important;
  width: 148px;
  height: 116px;
  background: #ffffff;
  box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.08);
  padding: 0 24px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 0;
}
.el-dropdown-menu.dropList li {
  font-size: 14px;
  color: #666;
  height: 57px;
  line-height: 57px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}
.el-dropdown-menu.dropList li:hover{
  color: #0780e6;
}
.el-dropdown-menu.dropList li:last-child {
  border-bottom: none;
}
.el-dropdown-menu.dropList li:hover {
  background-color: white;
}
.el-dropdown-menu.dropList .popper__arrow {
  display: none;
}
</style>
