import axios from '@/utils/http'

// 查询轮播图
export const bannerList = function (data) {
  return axios.get('/power/cms/banner/listPage', { params: data })
}

// 热门岗位
export const hotPost = function (data) {
  return axios.get('/power/cms/pRecruit/hot/listPage', { params: data })
}

// 优选推荐
export const recommendPost = function (data) {
  return axios.get('/power/cms/pRecruit/recommend/listPage', { params: data })
}

// 搜索
export const indexSearch = function (data) {
  return axios.get('/power/cms/pRecruit/condition/listPage', { params: data })
}

// 条件搜索
export const classificationSearch = function (data) {
  return axios.get('/power/cms/pRecruit/codition', { params: data })
}


// 求职者获取招聘详情
export const indexDetails = function (id) {
  return axios.get('/power/cms/pRecruit/condition/' + id)
}
export const customer = function (id) {
  return axios.get('/power/cms/pRecruit/customer/condition/' + id)
}
// 收藏
export const collectionAdd = function (data) {
  return axios.post('/power/cms/userCollection/add', data)
}

// 个人中心---取消收藏
export const collectionRemove = function (data) {
  return axios.delete('/power/cms/userCollection/remove/core', { data })
}

//岗位详情页/公司详情页---取消收藏
export const collectionRemove2 = function (data) {
  return axios.delete('/power/cms/userCollection/remove', { data })
}

// 查询职位收藏列表
export const collectionList = function (data) {
  return axios.get('/power/cms/userCollection/positionList', { params: data })
}


// 投递简历
export const deliveryAdd = function (data) {
  return axios.post('/power/cms/pDelivery/add', data)
}
// 查看岗位
export const viewAdd = function (data) {
  return axios.post('/power/cms/pRecruit/view/add', data)
}
// 底部数据
export const footer = function () {
  return axios.get('/power/cms/platform/information/detail')
}
